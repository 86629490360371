.input-text {
  width: 100%;
  display: block;
  background-color: $color-white;
  border-radius: 1.25rem;
  padding: 0 1rem;
  color: $color-black;
  font-weight: 600;
  height: 3.75rem;
  line-height: 3.75rem;
  border: 2px solid transparent;

  &:focus {
    outline: none;
    border: 2px solid $color-black;
    // box-shadow: 0px 3px 4px rgba(214, 194, 46, 0.75), inset 0px 4px 7px rgba(1, 1, 1, 0.25);
  }

  &.error {
    border-color: $color-red;
  }
}

input::placeholder {
  color: #B7B7B7;
}
textarea::placeholder {
  color: #B7B7B7;
}

// 선택 버튼
.selector {
  font-weight: 600;
  text-align: center;
  height: 7.5rem;
  padding-top: 1rem;
  background-color: $color-white;
  border-radius: 1.25rem;
  border: 2px solid transparent;

  &.active {
    border: 2px solid $color-black;
    background-position: center bottom 1rem;
    background-repeat: no-repeat;
    background-size: 1rem 1rem;
    background-image: url(../assets/icons/ico-check.svg);
  }
}

// Yes or no
.yes-or-no {
  li {
    background-color: $color-white;
    // box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.1);
    border-radius: 0.625rem;
    padding: 0.6875rem;
    height: 11.75rem;
    font-weight: 600;
    text-align: left;
    border: 2px solid transparent;
    width: calc(50% - 1.25rem);
    margin-right: 1.25rem;

    &:last-child {
      margin-right: 0;
    }

    &.active {
      border: 2px solid $color-black;

      .checked {
        background-position: right bottom;
        background-repeat: no-repeat;
        background-size: 1rem 1rem;
        background-image: url(../assets/icons/ico-check.svg);
      }
    }

    i {
      display: block;
      margin: 0 auto;
      width: 8.5rem;
      height: 8.5rem;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      background-image: url(../assets/images/logo.svg);
    }

    &.yes {
      i {
        background-image: url(../assets/images/img-yes.svg);
      }
    }
    &.no {
      i {
        background-image: url(../assets/images/img-no.svg);
      }
    }
  }
}

// 하단 버튼
footer {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 0 1.25rem;

  .footer-content {
    background-color: $color-white;
    height: 3.75rem;
    border-top-left-radius: 1.25rem;
    border-top-right-radius: 1.25rem;
    overflow: hidden;

    ul {
      display: flex;
      justify-content: center;

      li {
        width: 50%;
        flex-grow: 1;

        .footer-link {
          width: 100%;
          text-align: center;
          height: 3.75rem;
          line-height: 3.75rem;
          font-weight: 600;
          color: rgba(28, 28, 28, 0.4);
        }

        &.active {
          .footer-link {
            color: $color-black;
          }
        }
      }
    }
  }
}