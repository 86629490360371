.intro {
  .logo-wrap {
    height: calc(100% - 12rem - 1.5625rem - 1.75rem);
  }

  .title-wrap {
    padding: 2.5rem 0;

    .title {
      line-height: 1.75rem;
    }
  }
}