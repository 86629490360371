.quiz {
  position: relative;
  overflow-y: hidden;
  transition: all 500ms linear;
  padding-top: 0;

  .quiz-question {
    /*height: 100vh;
    overflow-y: auto;
    padding-bottom: 6.25rem;
    background-color: red;*/
  }

  .quiz-emoji {
    height: 12.5rem;
    transition: all 500ms linear;

    @media only screen and (max-width: 375px) {
      height: 10.5rem;
    }
  }

  .quiz-title {
    height: 6.25rem;
    padding: 1.25rem 0;
    position: relative;
    z-index: 10;
    background-color: $color-yellow;

    .no {
      width: 1.75rem;
      font-size: 1.5rem;
      line-height: 130%;
    }
  }

  .quiz-content {
    overflow: hidden;
  }

  .quiz-answer {
    transition: all 500ms linear;
  }

  .quiz-level {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    transform: translateY(100%);
    transition: all 500ms linear;

    &.on-level {
      transform: translateY(0);
    }

    .quiz-level-content {
      overflow-y: auto;
      -webkit-overflow-scrolling: auto;
    }

    ul.quiz-level-list {
      li {
        background-color: $color-white;
        // border: 2px solid transparent;
        border-radius: 1.25rem;
        display: flex;
        overflow: hidden;
        position: relative;

        &.current {
          border-color: $color-black;

          &:after {
            content: ' ';
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            border: 2px solid $color-black;
            border-radius: 1.25rem;
            z-index: 10;
          }

          &:before {
            content: ' ';
            width: 1rem;
            height: 1rem;
            position: absolute;
            right: 1rem;
            bottom: 0.625rem;
            background-position: right bottom;
            background-repeat: no-repeat;
            background-size: 1rem 1rem;
            background-image: url(../assets/icons/ico-check.svg);
            z-index: 10;
          }
        }

        .icon-wrap {
          width: 50%;
          height: 6.25rem;
          position: relative;
        }

        .icon {
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          z-index: 10;
          border-radius: 1.25rem;
        }

        .icon-bg {
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          z-index: 0;
          border-radius: 1.25rem;
          overflow: hidden;
        }

        .text {
          width: 50%;
          font-weight: 600;
          padding: 0.625rem 1rem 0.625rem 0.625rem;
          z-index: 10;
          word-break: keep-all;
        }

        // 세부 아이콘
        &.quiz-level-item {
          .icon {
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
          }

          @for $i from 1 through 10 {
            @for $j from 1 through 6 {
              &.quiz-level-item-#{$i}-#{$j} {
                .icon {
                  background-image: url('../assets/images/q/Q.#{$i}-#{$j}.svg');
                }
              }
            }
          }
        }
      }
    }
  }

  &.on-level {
    padding-top: 0;

    .quiz-emoji {
      height: 0;
    }

    .quiz-content {
      .quiz-answer {
        transform: translateY(-100%);
      }

      .quiz-level {
        transform: translateY(-11.75rem);
      }
    }
  }
}

.score {
  position: absolute;
  width: 100%;
  height: 100%;
  transition: all 500ms linear;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  .score-box {
    display: flex;
    justify-content: center;
    align-items: center;

    .loading {

    }

    .text {
      transition: all 500ms linear;
    }
  }

  .total-score {
    height: 0;
    transition: visibility 300ms linear;
  }

  &.scored {
    margin: 0;

    .score-box {
      display: none;

      .loading {
        display: none;
      }

      .text {
        top: 3.3125rem;
      }
    }

    .total-score {
      font-size: 7.5rem;
      line-height: 9.75rem;
      height: 9.75rem;
    }
  }
}