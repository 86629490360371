.btn {
  &.btn-block {
    width: 100%;
    height: 3.75rem;
    line-height: 3.75rem;
    background-color: $color-black;
    border-radius: 2.185rem;
    color: $color-white;
    text-align: center;
    font-size: 1.3125rem;
    letter-spacing: 0.035rem;
    font-weight: 700;

    &.disabled {
     background-color: $color-gray;
    }
  }

  &.btn-arrow-right {
    text-indent: -9999rem;
    width: 3.875rem;
    height: 3.75rem;
    background-color: $color-black;
    border-radius: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 1.1875rem 2rem;
    background-image: url(../assets/icons/ico-arrow-right.svg);
  }

  &.btn-back {
    text-indent: -9999rem;
    width: 1rem;
    height: 1.5rem;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    background-image: url(../assets/icons/ico-arrow-left.svg);
  }

  &.btn-home {
    text-indent: -9999rem;
    width: 1.75rem;
    height: 1.75rem;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 1.75rem 1.75rem;
    background-image: url(../assets/icons/ico-home.svg);
  }

  &.btn-close {
    text-indent: -9999rem;
    width: 1.75rem;
    height: 1.75rem;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 1.75rem 1.75rem;
    background-image: url(../assets/icons/ico-close.svg);
  }
}