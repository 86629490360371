.logo {
  width: 9.9375rem;
  height: 3.5rem;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url(../assets/images/logo.svg);
}

.loading {
  width: 5rem;
  height: 5rem;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url(../assets/icons/ico-loading.svg);
}