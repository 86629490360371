html,
body,
#root {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  @supports (-webkit-touch-callout: none) { height: -webkit-fill-available; }
}

body {
  color: $color-black;
  -webkit-font-smoothing: subpixel-antialiased;
  -moz-osx-font-smoothing: grayscale;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  font-family: Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, 'Helvetica Neue', 'Segoe UI', 'Apple SD Gothic Neo', 'Noto Sans KR', 'Malgun Gothic', sans-serif;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow-y: scroll;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  font-size: $font-size;
  line-height: $line-height;

  @media only screen and (min-width: 1367px) {
    background-color: #E0E0E0;
  }
}

// iPhone X
@media only screen and (max-width: 414px) {
  html {
    font-size: 16px;
  }
}

// iPhone 6,7,8,SE2
@media only screen and (max-width: 375px) {
  html {
    font-size: 16px;
  }
}

// Galaxy
@media only screen and (max-width: 360px) {
  html {
    font-size: 14px;
  }
}

// iPhone SE
@media only screen and (max-width: 320px) {
  html {
    font-size: 12px;
  }
}

// Section
.section {
  @media only screen and (min-width: 1367px) {
    max-width: 375px;
    margin: 0 auto;
    position: relative;
  }
}

.init-hidden {
  opacity: 0;
  visibility: hidden;
  transition: all 500ms ease-in-out;

  &.show {
    opacity: 1;
    visibility: visible;
  }
}

input {
  border-radius: 0;
}

// Reset input
input {
  -webkit-appearance: none;
}

// Page
.page {
  position: relative;
  width: 100%;
  height: 100vh; /* Fallback for browsers that do not support Custom Properties */
  height: calc(var(--vh, 1vh) * 100);
  padding: 1.5rem 1.25rem (4.75rem + 1.5rem) 1.25rem;
  background-color: $color-white;
  overflow-y: auto;

  p {
    line-height: 130%;

    &.small {
      font-size: 0.8125rem;
      line-height: 1rem;
    }

    &.medium {
      font-size: 0.9375rem;
      line-height: 1.25rem;
    }
  }

  .text-toggle {
    transition: all 100ms linear;
    opacity: 1;
    visibility: visible;

    &.text-hidden {
      opacity: 0;
      visibility: hidden;
    }
  }
}

// Font Color
.color-gray {
  color: $color-dark-gray;
}
.color-red {
  color: $color-red;
}

// BG Color
.bg-yellow {
  background-color: $color-yellow;
}
.bg-black {
  background-color: $color-black;
}

