.progress {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 2px;
  z-index: 50;
  opacity: 1;
  visibility: visible;
  width: 100%;

  &:before {
    content: ' ';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 2px;
    background-color: $color-white;
  }

  .progress-per {
    position: absolute;
    left: 0;
    top: 0;
    height: 2px;
    background-color: $color-black;
    transition: width 500ms linear;
  }

  &.hide {
    opacity: 0;
    visibility: hidden;
  }
}