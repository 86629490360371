.confirm {
  padding: 0 1.25rem;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 50;
  display: none;

  &.show {
    display: flex;
  }

  &:before {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    content: ' ';
    background-color: rgba(28, 28, 28, 0.2);
  }

  .confirm-dialog {
    position: relative;
    width: 100%;
    height: auto;
    background-color: $color-white;
    box-shadow: 0px 30px 40px rgba(0, 0, 0, 0.4);
    border-radius: 1.25rem;
    z-index: 10;
    overflow: hidden;

    .confirm-body {
      padding: 1rem 3.75rem 3rem 1.25rem;
    }

    .confirm-footer {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-grow: 1;

      button {
        width: 50%;
        height: 3.75rem;
        line-height: 3.75rem;
        text-align: center;
        font-size: 1.0625rem;
        font-weight: 600;

        &.btn-confirm.primary {
          background-color: $color-black;
          color: $color-white;
        }
      }
    }
  }
}